/* autoprefixer grid: autoplace */
@import-normalize;

.rv-xy-plot__axis__tick__text {
    fill: #7AB9DB
}

.rv-discrete-color-legend-item__title {
    color:white;
    font-weight: bold;
}


/* React-Calendar-Timeline overrides */
.rct-header-root {
    background-color: #2E4162 !important;
}

.rct-outer {
    background-color: #1f1e2cc7;
}

.rct-dateHeader {
    background-color: #6E8093 !important;
    color: #FFFFFF;
}

.rct-dateHeader-primary {
    background-color: initial !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background-color: transparent !important;
}

/* React-Grid-Layout overrides */
.react-grid-layout {
    margin: 10px 0;
    background-color: #54657f;
    min-height: 70px;
}

.react-grid-item {
    background-color: #34435a;
    border: 1px solid #7AB9DB;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*** General CSS ***/
.width-50 {
    width: 50%;
}

/*** React JSONSCHEMA FORM **/

.hidden-title>label {
    display: none;
}

@keyframes spin {
    from {
      transform: rotate(0deg)
    }
    to {
     transform: rotate(-360deg)
    }
}
.spin {
      animation: spin 500ms linear infinite;
}

.tooltip-circle {
    cursor: pointer;
  }

  .graph-svg {
    fill: #000;
    fill-opacity: 0.3;
    background-color: rgb(31, 30, 44);
  }

  .graph-line-path {
    fill: none;
  }

  .axis {
    stroke: #fff;
  }

  .axis-labels text {
    fill: #fff;
    fill-opacity: 0.9;
    font-size: 12px;
    text-anchor: middle;
  }

  .axis-labels line {
    stroke: #fff;
  }

  .gridline {
    opacity: 0.2;
  }

  .tooltip-circle-highlighted {
    stroke-width: 20;
	stroke-linecap: round;
  }

  .u-tooltip {
    background-color: rgb(200, 200, 176);
    position: fixed;
  }
  
  .u-over {
    cursor: pointer;
  }
  
  .u-legend {
    font-size: 12px;
    text-align: left;
  }
  
  /* finalize legend ui*/
  /* td.u-value {
    max-width: 160px;
    min-width: 60px;
  } */
  
  /* #canvas-graph tbody>tr:nth-child(1)>td {
    min-width: 150px;
    max-width: 220px;
  } */
  
  .threshold-legend {
    display: none
  }
  
  .u-select {
    background: #6666774f
  }

  .u-legend  tr:nth-child(1) > th {
    display: none;
  }

  .u-legend  tr:nth-child(1) > td {
    font-size: 12px;
    padding-left: 16px;
  }
  
  .u-legend tr.alert-info{
    display: none;
  }

  .u-series > * {
    padding: 1px;
}
  .accordion-icon {
    color:#8093A7
  }
  .field-description{
    padding:4px 0;
    margin:2px;
  }
  .invalid-note{
    color: red;
  }
  #settings-gear, #drag-handle  {
    opacity: 0.4;
    transition: 0.5s;
  }
  #settings-gear:hover, #drag-handle:hover {
    opacity: 1;
  }

  .keyword {
    color:#8db3f5;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 100px;
  }
  .datasource {
    color:rgb(160, 245, 160);
    text-transform: lowercase;
  }
.alert-definition-list a {
  color: white;
  font-weight: 800;
}
.alert-detail-key{
  padding-top: 2px;
  text-transform: uppercase;
  font-size: 10px;
}

.alert-detail-value {
  font-size: 10px;
}
.critical {
    color: #DB4444;
    font-weight: 600;
}

.warning{
  color: #FFCB66;
}
.normal{
  color: #88BD40;
}

.condition{
  font-weight: 600;
}

.tooltip-hover{
  max-width: 200px;
}

.rjsf > div > button.btn {
  display: none;
}

.multi-select-gs .checkboxes {
 display: flex;
 flex-direction: row;
 flex-flow: wrap;

 .checkbox {
  font-size: 12px;
  padding: 6px;
  margin: 2px; 
  overflow: visible;
  cursor: auto;
  overflow: visible;
  display: inline-block;
  border: 1px solid #7AB9DB40;
  border-radius: 3px;
  -webkit-transition: all 150ms;
  transition: all 150ms;
  cursor: pointer;
}
}

.multi-select-gs .checkbox label span:last-child {
  color: #8093A7;
  padding-left: 4px;
}
 
.multi-select-sats select option {
  font-size: 12px;
}

.daterange-passage-manager,
.daterange-planning-system {
  .rdtDays, .rdtPicker{
    color: #7ab9dc;
  }
}

.custom-select__single-value{
  text-transform: none;
}

.daterange-passage-manager-fetch-from #root_daterange_from input,
.daterange-passage-manager-fetch-from #root_daterange_to input,
.daterange-passage-manager-fetch-to #root_daterange_from input,
.daterange-passage-manager-fetch-to #root_daterange_to input {
  width: auto;
}

.daterange-passage-manager-fetch .rdtPicker td,
.daterange-passage-manager-fetch .rdtPicker th {
  color: #7ab9dc;
}